<template>
<!--选择质量通知单  -->
    <div class="list">
      <list-search :childData="childData" @onSearch="onSearch" @clearSearch="clearSearch"></list-search>
      <global-table ref="table" :tableField="dialogtableField"  :tableData="dialogtatableData" :paginationData="paginationData" @pageChange="pageChange" @selection-change="SelectionChange"></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import { SearchList, qualitySearchData, dialogtableField, dialogtatableData } from './js/setting'
export default {
  components: Component.components,
  name: 'ChoosequalityDialog',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: {
        searchList: SearchList,
        searchData: qualitySearchData
      },
      // tableField: tableField,
      // tableData: tableData,
      searchdata: qualitySearchData,
      dialogtableField: dialogtableField,
      dialogtatableData: dialogtatableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      }
    }
  },
  mounted () {
    this.noticeList()
  },
  methods: {
    // 获取质量协同-采购-质量通知单数据列表
    noticeList () {
      const obj = {
        tzDh: this.childData.searchData.tzDh,
        qualityCode: this.childData.searchData.qualityCode,
        supplier: this.childData.searchData.supplier,
        type: this.childData.searchData.type,
        status: 3,
        plant: this.childData.searchData.plant
      }
      request('/api/quality/list?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize, 'GET', obj).then((res) => {
        if (res.code === '200') {
          this.dialogtatableData = res.data.records
          console.log('222', this.dialogtatableData)
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    SelectionChange (selectData) {
      console.log('selectData', selectData)
      // selectData.forEach(item => {
      //   console.log('item', item)
      // })
    },
    onSearch (data) {
      this.pageNum = 1
      this.noticeList()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.noticeList()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.noticeList()
    }
  }
}
</script>
<style scoped lang="scss">
.list{
:deep(.tableNav){
  border-bottom: none;
}
}

</style>
